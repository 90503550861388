<template>
  <reservation-stepper />
</template>

<script>
import ReservationStepper from './ReservationStepper'
export default {
  components: {
    ReservationStepper
  }
}
</script>

<style>

</style>
